<template>
  <div id="public-privacy-policy" class="padding-btm-channel-link">
    <el-row class="title-row" type="flex" justify="space-between">
      <span class="text-primary text-bold">{{ $t("PRIVACY_POLICY") }}</span>
      <el-divider />
    </el-row>

    <div class="content-wrapper">
      <div v-html="html" />
    </div>
  </div>
</template>

<script>
import { getPrivacyPolicyList } from "@/api/site-settings"
import cloneDeep from "lodash/cloneDeep"
import { generalMixin } from '@/utils/general-mixin.js';

export default {
  name: "ContactUs",
  data() {
    return {
      detail: {}
    }
  },
  mixins: [ generalMixin ],
  computed: {
    html() {
      return this.getNameFromTransList(this.detail)
    }
  },
  methods: {
    getDetail() {
      this.isLoading = true
      getPrivacyPolicyList().then((res) => {
        if (res.status !== "ok") return
        const firstEl = res.result.list[0]
        
        if (!firstEl || firstEl.status != "ACTIVE") {
          this.goTo("/")
          return
        }

        if (firstEl) {
          this.detail = cloneDeep(firstEl)
        }

      })
    }
  },
  mounted() {
    this.getDetail()
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";

#public-privacy-policy {
  .title-row {
    span {
      margin: auto;
      white-space: nowrap;
      min-height: 1.75rem;
    }
    .el-divider {
      margin: 1.7rem 0.5rem;
    }
  }

  .content-wrapper {
    background-color: $white;
    padding: 1.5rem;
  }
}

</style>